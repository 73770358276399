<template>
  <Breadcrumb />
  <div
    class="d-flex justify-content-end"
    v-if="this.$store.getters._isSuperAdmin"
  >
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t("Projects.NewButton", {}, { locale: this.$store.state.activeLang })
      }}</router-link
    >
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onCompanyClearCacheButtonClick="onCompanyClearCacheButtonClick"
    @onCompanyResetCacheButtonClick="onCompanyResetCacheButtonClick"
  />
  <AreYouSureModal
    :modalType="areYouSureModalType"
    :warningMessage="areYouSureModalWarningMessage"
    :requestParameter="areYouSureModalRequestParameter"
    :data="projectData"
    :isBrsAxios="true"
  />
</template>
<script>
import AreYouSureModal from "@/components/layout/AreYouSureModal.vue";
export default {
  name: "ProjectList",
  components: {
    AreYouSureModal,
  },
  data() {
    return {
      areYouSureModalType: "",
      areYouSureModalRequestParameter: "",
      areYouSureModalWarningMessage: "",
      projectData: {
        CompanyId: "",
        ProcessType: "",
      },
      isCompanyCache: false,
      gridSettings: {
        action: "ProjectList",
        requestUrl: String.format("/Lcdp-ArgesetList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "projectId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/Project/Detail/`,

        httpRequestType: "POST",
        allowSearchPanel: true,
        allowExcelExport: true,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        allowReset: true,
        autoOrderProcess: true,
        isGatewayRequest: true,
        areYouSureModalType: "",
        areYouSureModalRequestParameter: "",
        areYouSureModalWarningMessage: "",
        buttons: [
          // {
          //   name: "detail",
          //   cssClass: "btn-primary",
          //   iconClass: "bi-arrow-up-right-square",
          //   routeButton: true,
          //   routeObj: {
          //     name: "ProjectDetail",
          //     params: {
          //       projectId: "",
          //     },
          //   },
          // },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "ProjectEdit",
              params: {
                projectId: "",
              },
            },
          },
          {
            name: "reset",
            cssClass: "btn-outline-info",
            iconClass: "bi bi-recycle",
            routeButton: false,
            emitMethodName: "onCompanyResetCacheButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalAreYouSure",
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Components.AreYouSureModal.ResetCompanyCacheTitle",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "clear",
            cssClass: "btn-outline-danger",
            iconClass: "bi bi-x-circle-fill",
            routeButton: false,
            emitMethodName: "onCompanyClearCacheButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalAreYouSure",
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Components.AreYouSureModal.ClearCompanyCacheTitle",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Projects.CompanyId",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "companyId",
          type: "string",
          visible: true,
          width: 100,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Projects.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Projects.CompanyName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "appName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Projects.CompanyAdminEmail",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "contactPersonEmail",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Projects.RelatedPerson",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "contactPerson",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Projects.UserCount",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "startUserCount",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Projects.ActiveUserCount",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "currentUserCount",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Projects.DateOfContract",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "contractStartDate",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onCompanyResetCacheButtonClick(rowData) {
      this.projectData.CompanyId = rowData.companyId;
      this.projectData.ProcessType = "Reset";
      this.areYouSureModalType = "ResetCompanyCache";
      this.areYouSureModalRequestParameter = rowData.companyId;
      this.areYouSureModalWarningMessage = this.$t(
        "Components.AreYouSureModal.CompanyResetCacheWarning",
        {},
        { locale: this.$store.state.activeLang }
      );
      this.isCompanyCache = true;
    },
    onCompanyClearCacheButtonClick(rowData) {
      this.projectData.CompanyId = rowData.companyId;
      this.projectData.ProcessType = "Clear";
      this.areYouSureModalType = "ClearCompanyCache";
      this.areYouSureModalRequestParameter = rowData.companyId;
      this.areYouSureModalWarningMessage = this.$t(
        "Components.AreYouSureModal.CompanyClearCacheWarning",
        {},
        { locale: this.$store.state.activeLang }
      );
      this.isCompanyCache = true;
    },
  },
  mounted() {},
};
</script>
